<template>
  <v-container fluid>
    <h3>Daily Customer</h3>

    <v-row justify="center">
      <v-btn
        fab
        color="cyan"
        absolute
        top
        class="mt-6"
        small
        right
        @click.native.stop="reload"
      >
        <v-icon color="white">refresh</v-icon>
      </v-btn>
    </v-row>
    <v-sheet elevation="1">
      <v-row v-if="buyers" justify="center" class="px-8">
        <div v-for="buyer in buyers" :key="buyer">
          <div class="pa-2 text-no-wrap">
            <v-icon :color="colors(buyer)" style="opacity: 0.6"
              >mdi-rectangle</v-icon
            >
            {{ buyer }}
          </div>
        </div>
      </v-row>
      <v-row>
        <v-col cols="12" md="8" class="d-flex justify-center px-8 py-4">
          <div v-resize="onResize" ref="svgContainer" class="w-100">
            <svg :width="width" :height="height" class="svgPie" />
          </div>
        </v-col>
        <v-col cols="12" md="4" v-if="selectedFeeInfo">
          <RevenueTable :tableData="selectedFeeInfo" />
        </v-col>
      </v-row>
      <v-row class="px-8">
        <v-col cols="12" sm="6" md="4" lg="3">
          <v-dialog
            ref="dialog"
            v-model="modal"
            persistent
            :return-value.sync="select_dates"
            width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                v-model="dateRangeText"
                label="Date Range"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                hide-details="auto"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="select_dates"
              range
              :max="new Date().toISOString().substr(0, 10)"
              ><v-spacer></v-spacer>
              <v-btn text color="primary" @click="modal = false">
                Cancel
              </v-btn>
              <v-btn text color="primary" @click="onSelectDate">
                OK
              </v-btn></v-date-picker
            >
          </v-dialog>
        </v-col>
        <v-col cols="12" sm="6" md="8" lg="9">
          <v-combobox
            v-model="buyers"
            :items="buyersName(orders)"
            label="By Buyers"
            multiple
            chips
            hide-details="auto"
            dense
            class="pt-1"
          ></v-combobox>
        </v-col>
      </v-row>
    </v-sheet>

    <v-btn icon to="/report" class="ml-2">
      <v-icon color="info">arrow_back</v-icon>
    </v-btn>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import moment from 'moment'
import * as d3 from 'd3'
import resize from 'vue-resize-directive'
import RevenueTable from './RevenueTable'

export default {
  directives: {
    resize
  },
  components: {
    RevenueTable
  },
  data() {
    return {
      width: 0,
      height: 400,
      modal: false,
      orders: [],
      buyers: [],
      selectedFeeInfo: null,
      select_dates: [
        d3.timeFormat('%Y-%m-%d')(Date.now()),
        d3.timeFormat('%Y-%m-%d')(Date.now())
      ],
      range_dates: [
        d3.timeFormat('%Y-%m-%d')(Date.now()),
        d3.timeFormat('%Y-%m-%d')(Date.now())
      ]
    }
  },
  computed: {
    ...mapGetters({
      profile: 'profile'
    }),
    dateRangeText() {
      return this.range_dates.join(' ~ ')
    },
    colors() {
      return d3
        .scaleOrdinal()
        .domain(this.buyers)
        .range(this.randomHsl(this.buyers.length))
    },
    daysInRange() {
      let date = []
      let from = this.range_dates[0],
        to = this.range_dates[1]
      while (moment(from) <= moment(to)) {
        date.push(from)
        from = moment(from)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      }
      return date
    }
  },
  async mounted() {
    this.onResize()
    await this.reload()
  },
  watch: {
    range_dates() {
      this.reload()
    },
    buyers() {
      this.selectedFeeInfo = this.orders.filter(o =>
        this.buyers.includes(o.buyer.name)
      )
      this.renderChart()
    },
    width: function(newVal, oldVal) {
      if (newVal !== oldVal) {
        this.renderChart()
      }
    }
  },
  methods: {
    buyersName(orderData) {
      return [...new Set(orderData.map(e => e.buyer.name).filter(e => e))]
    },
    onSelectDate() {
      this.$refs.dialog.save(this.select_dates)
      this.range_dates = this.select_dates
    },
    randomHsl(size) {
      return [...Array(size).keys()].map(
        () => `hsla(${Math.random() * 540}, 100%, 50%, 1)`
      )
    },
    onResize() {
      this.width = this.$refs.svgContainer.clientWidth
    },

    async reload() {
      await this.loadOrders()
      await this.renderChart()
    },
    async renderChart() {
      if (!this.orders) {
        console.warn(`orders is empty`)
        return
      }

      const filteredOrders = this.orders.filter(o =>
        this.buyers.includes(o.buyer.name)
      )

      let data = d3
        .nest()
        .key(e => e.buyer.name)
        .entries(filteredOrders)
        .reduce((a, c) => {
          a[c.key] = c.values.length
          return a
        }, {})

      d3.select('.svgPie')
        .selectAll('*')
        .remove()

      const pie = d3.pie().value(d => d.value)
      const data_ready = pie(d3.entries(data))

      const radius = Math.min(this.width, this.height) * 0.4

      const graph = d3
        .select('.svgPie')
        .append('g')
        .attr('transform', `translate(${this.width / 2}, ${this.height / 2})`)
      const that = this
      graph
        .selectAll('slices')
        .data(data_ready)
        .enter()
        .append('path')
        .attr(
          'd',
          d3
            .arc()
            .innerRadius(0)
            .outerRadius(radius)
            .cornerRadius(4)
        )
        .attr('fill', d => this.colors(d.data.key))
        .attr('stroke', 'lightgrey')
        .style('stroke-width', '4px')
        .style('opacity', 0.6)
        .style('cursor', 'pointer')
        .on('mouseover', function(d) {
          // get fee information
          d3.event.preventDefault()
          that.selectedFeeInfo = filteredOrders.filter(
            odr => odr.buyer.name === d.data.key
          )

          d3.select(this).style('opacity', 1)
          graph.selectAll('.shadow-slices').remove()
          graph
            .selectAll('.shadow-slices')
            .data(data_ready)
            .enter()
            .append('path')
            .attr('class', 'shadow-slices')
            .attr(
              'd',
              d3
                .arc()
                .innerRadius(0)
                .outerRadius(radius * 1.15)
                .cornerRadius(4)
            )
            .attr('fill', e =>
              e.data.key === d.data.key
                ? that.colors(d.data.key)
                : 'transparent'
            )
            .attr('stroke', e =>
              e.data.key === d.data.key ? 'lightgrey' : 'transparent'
            )
            .style('stroke-width', '4px')
            .style('pointer-events', 'none')

          graph.selectAll('text').raise()

          graph.append('title').text(`${d.data.key} : ${d.data.value} order(s)`)
        })
        .on('mouseout', function() {
          d3.select(this).style('opacity', 0.6)
          graph.selectAll('.shadow-slices').remove()
          graph.selectAll('title').remove()
          that.selectedFeeInfo = filteredOrders
        })

      const arcGenerator = d3
        .arc()
        .innerRadius(20)
        .outerRadius(50)
      graph
        .selectAll('slices')
        .data(data_ready)
        .enter()
        .append('text')
        .style('pointer-events', 'none')
        .text(d => d.data.key)
        .attr('transform', function(d) {
          const [x, y] = arcGenerator.centroid(d)
          const rotation =
            d.endAngle < Math.PI
              ? ((d.startAngle / 2 + d.endAngle / 2) * 180) / Math.PI - 90
              : ((d.startAngle / 2 + d.endAngle / 2 + Math.PI) * 180) /
                  Math.PI +
                90

          return `translate(${x}, ${y}) rotate(${rotation})`
          // return 'rotate(' + arcGenerator.centroid(d) + ')'
        })
        // .style('text-anchor', 'start')
        .style('font-size', '11px')
        .raise()
    },
    async loadOrders() {
      if (this.profile && this.profile.id) {
        await this.$store
          .dispatch('loadProducerOrdersFromRange', {
            uid: this.profile.id,
            from: this.range_dates[0],
            to: this.range_dates[1],
            range: this.daysInRange
          })
          .then(orders => {
            this.orders = orders
            this.buyers = this.buyersName(orders)
          })
      }
    }
  }
}
</script>
