<template>
  <v-container fluid>
    <v-sheet elevation="1">
      <v-row v-if="calcTableData" justify="center" class="px-4">
        <v-col>
          <div
            v-for="[key, value] in Object.entries(calcTableData)"
            :key="key"
            class="pa-2"
          >
            <div class="d-flex justify-space-between text-no-wrap">
              <p class="font-weight-bold">{{ key }}</p>
              <p :class="value < 0 ? 'red--text' : 'black--text'">
                {{ value | currency }}
              </p>
            </div>
          </div>
        </v-col>
      </v-row>
    </v-sheet>
  </v-container>
</template>
<script>
export default {
  props: {
    tableData: {
      type: Array
    }
  },
  computed: {
    calcTableData() {
      if (!this.tableData || !Object.values(this.tableData).length) return null
      return this.tableData
        .map(record => {
          try {
            return {
              Subtotal: record.payment.all_fees.sub_total || 0,
              'Net.Subtotal':
                record.payment.all_fees.producer_sub_total_net || 0,
              'Tip Amount': record.payment.all_fees.producer_tip || 0,
              Tax: record.payment.all_fees.producer_tax || 0,
              'Service Fee': -record.payment.all_fees.producer_service_fee || 0,
              'CC Processing Fee':
                -record.payment.all_fees.producer_payment_fee || 0,
              'Total Revenue': record.payment.all_fees.producer_total || 0
            }
          } catch (e) {
            console.log(e)
            return {
              Subtotal: 0,
              'Net.Subtotal': 0,
              'Tip Amount': 0,
              Tax: 0,
              'Service Fee': 0,
              'CC Processing Fee': 0,
              'Total Revenue': 0
            }
          }
        })
        .reduce(
          (a, c) => {
            Object.entries(c).forEach(([key, value]) => {
              a[key] += value
            })
            return a
          },
          {
            Subtotal: 0,
            'Net.Subtotal': 0,
            'Tip Amount': 0,
            Tax: 0,
            'Service Fee': 0,
            'CC Processing Fee': 0,
            'Total Revenue': 0
          }
        )
    }
  }
}
</script>
